var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-card",
              {
                directives: [
                  {
                    name: "resize",
                    rawName: "v-resize",
                    value: _vm.onResize,
                    expression: "onResize",
                  },
                ],
                attrs: {
                  height: _vm.isDashboard ? 450 : 600,
                  loading: _vm.isLoading,
                  elevation:
                    !_vm.isDashboard || !_vm.isEditMode ? 2 : hover ? 20 : 5,
                },
              },
              [
                _c(
                  "v-card-title",
                  { staticClass: "pb-0" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.initialYw.year) +
                        "年第" +
                        _vm._s(_vm.initialYw.week) +
                        "週の" +
                        _vm._s(_vm.dataTypeText) +
                        "内訳 "
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-fade-transition",
                      [
                        !_vm.isDashboard || _vm.isEditMode
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.onClickClose },
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      { staticClass: "ml-0 mt-0" },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            disabled: _vm.initialDataType === "stockout",
                            label: "差分を見る:",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.isDeviation,
                            callback: function ($$v) {
                              _vm.isDeviation = $$v
                            },
                            expression: "isDeviation",
                          },
                        }),
                        _c(
                          "v-col",
                          { staticClass: "pt-4", attrs: { cols: "8" } },
                          [
                            _c("chip-menu", {
                              attrs: {
                                disabled: !_vm.isDeviation,
                                "list-items": _vm.years,
                                "chip-text": `${String(_vm.compYw.year)}年`,
                                "chip-props": {
                                  outlined: true,
                                  label: true,
                                },
                                "chip-class": "mr-1",
                              },
                              on: {
                                "click:list": function ($event) {
                                  _vm.compYw.year = $event
                                },
                              },
                            }),
                            _c("chip-menu", {
                              attrs: {
                                disabled: !_vm.isDeviation,
                                "list-items": _vm.weeks,
                                "chip-text": `第${String(_vm.compYw.week)}週`,
                                "chip-props": {
                                  outlined: true,
                                  label: true,
                                },
                                "chip-class": "mr-1",
                              },
                              on: {
                                "click:list": function ($event) {
                                  _vm.compYw.week = $event
                                },
                              },
                            }),
                            _vm._v(" との比較 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mt-5 d-flex flex-row align-baseline" },
                      [
                        _c("chip-menu", {
                          attrs: {
                            "list-items": _vm.dataUnits,
                            "chip-text": _vm.dataUnits.find(
                              (el) => el.value == _vm.dataUnit
                            ).text,
                            "chip-props": {
                              outlined: true,
                              label: true,
                              small: true,
                            },
                            "chip-class": "ml-4",
                          },
                          on: {
                            "click:list": function ($event) {
                              _vm.dataUnit = $event.value
                            },
                          },
                        }),
                        _c("chip-menu", {
                          attrs: {
                            "list-items": _vm.rankingOrders,
                            "chip-text": _vm.rankingOrders.find(
                              (el) => el.value == _vm.rankingOrder
                            ).text,
                            "chip-props": {
                              outlined: true,
                              label: true,
                              small: true,
                            },
                            "chip-class": "mr-1 ml-1",
                          },
                          on: {
                            "click:list": function ($event) {
                              _vm.rankingOrder = $event.value
                            },
                          },
                        }),
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "2" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                label: "最高順位",
                                suffix: "位",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.setChart()
                                },
                              },
                              model: {
                                value: _vm.ranking[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.ranking, 0, $$v)
                                },
                                expression: "ranking[0]",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" から "),
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "2" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                label: "最低順位",
                                suffix: "位",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.setChart()
                                },
                              },
                              model: {
                                value: _vm.ranking[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.ranking, 1, $$v)
                                },
                                expression: "ranking[1]",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" までを表示 "),
                        _c(
                          "v-btn",
                          { attrs: { icon: "" }, on: { click: _vm.setChart } },
                          [_c("v-icon", [_vm._v("mdi-reload")])],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.isRankingValid
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pl-4 py-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-alert",
                                  { attrs: { dense: "", type: "warning" } },
                                  [_vm._v("無効な順位です。 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isDataUnavailable
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pl-4 py-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-alert",
                                  { attrs: { dense: "", type: "warning" } },
                                  [
                                    _vm._v(
                                      "該当週または比較週のデータが存在しません。 "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          absolute: "",
                          "open-on-hover": "",
                          transition: "fade-transition",
                          "open-on-click": false,
                          "close-on-click": false,
                          "close-on-content-click": false,
                          "position-x": _vm.tooltipInfo.positionX
                            ? _vm.tooltipInfo.positionX
                            : 0,
                          "position-y": _vm.tooltipInfo.positionY
                            ? _vm.tooltipInfo.positionY
                            : 0,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      _vm._b(
                                        { ref: "chart" },
                                        "div",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "v-card",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isTooltipShown,
                                expression: "isTooltipShown",
                              },
                            ],
                          },
                          [
                            _c("v-card-text", [
                              ["stocks", "shipments", "remains"].includes(
                                _vm.initialDataType
                              )
                                ? _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "商品名：" +
                                          _vm._s(_vm.tooltipInfo.productName)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "商品コード：" +
                                          _vm._s(_vm.tooltipInfo.productCode)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "仕入先：" +
                                          _vm._s(_vm.tooltipInfo.supplier)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "ランク：" +
                                          _vm._s(_vm.tooltipInfo.rank)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "単価：" +
                                          _vm._s(_vm.tooltipInfo.price) +
                                          "円"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "バラ数：" +
                                          _vm._s(_vm.tooltipInfo.amount)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "合計金額：" +
                                          _vm._s(_vm.tooltipInfo.totalPrice) +
                                          "円"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.initialDataType === "products"
                                ? _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "仕入先名：" +
                                          _vm._s(_vm.tooltipInfo.supplierName)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "仕入先コード：" +
                                          _vm._s(_vm.tooltipInfo.supplierCode)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "商品数：" +
                                          _vm._s(_vm.tooltipInfo.amount)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.initialDataType === "stockout"
                                ? _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "商品名：" +
                                          _vm._s(_vm.tooltipInfo.productName)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "商品コード：" +
                                          _vm._s(_vm.tooltipInfo.productCode)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "仕入先：" +
                                          _vm._s(_vm.tooltipInfo.supplier)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "ランク：" +
                                          _vm._s(_vm.tooltipInfo.rank)
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "単価：" +
                                          _vm._s(_vm.tooltipInfo.price) +
                                          "円"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "欠品率：" +
                                          _vm._s(_vm.tooltipInfo.stockout) +
                                          "%"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
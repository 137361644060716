var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-card",
              {
                directives: [
                  {
                    name: "resize",
                    rawName: "v-resize",
                    value: _vm.onResize,
                    expression: "onResize",
                  },
                ],
                attrs: {
                  height: _vm.isDashboard ? 450 : 600,
                  loading: _vm.isLoading,
                  elevation:
                    !_vm.isDashboard || !_vm.isEditMode ? 2 : hover ? 20 : 5,
                },
              },
              [
                _c(
                  "v-card-title",
                  [
                    _vm._v(" 全商品対象の時系列推移 "),
                    _c("v-spacer"),
                    _c(
                      "v-fade-transition",
                      [
                        _vm.isDashboard && _vm.isEditMode
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.onClickClose },
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-1 pr-1 pb-0",
                            attrs: { cols: "4" },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                "offset-y": "",
                                label: "棒グラフに描画するデータ",
                                items: _vm.dataTypes,
                                "item-text": "text",
                                "itme-value": "value",
                                "prepend-inner-icon": "mdi-chart-bar",
                              },
                              model: {
                                value: _vm.selectedDataTypes[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectedDataTypes, 0, $$v)
                                },
                                expression: "selectedDataTypes[0]",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-1 pr-1 pb-0",
                            attrs: { cols: "4" },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                "offset-y": "",
                                label: "折線グラフに描画するデータ",
                                items: _vm.dataTypes,
                                "item-text": "text",
                                "itme-value": "value",
                                "prepend-inner-icon": "mdi-chart-line",
                              },
                              model: {
                                value: _vm.selectedDataTypes[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectedDataTypes, 1, $$v)
                                },
                                expression: "selectedDataTypes[1]",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-1 pb-0", attrs: { cols: "4" } },
                          [
                            _c("v-select", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                "offset-y": "",
                                label: "棒グラフの分類方法",
                                items: _vm.classifTypes,
                                "item-text": "text",
                                "itme-value": "value",
                                "prepend-inner-icon": "mdi-chart-bar-stacked",
                              },
                              model: {
                                value: _vm.selectedClassif,
                                callback: function ($$v) {
                                  _vm.selectedClassif = $$v
                                },
                                expression: "selectedClassif",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("yw-range-menu", {
                      attrs: {
                        "row-class": "mt-0",
                        "acceptable-year": _vm.ywRangeAcceptableYear,
                        "initial-yw-range": [_vm.startYw, _vm.endYw],
                        "chip-props": {
                          small: _vm.isDashboard ? true : false,
                          outlined: true,
                          label: true,
                        },
                      },
                      on: { click: _vm.getYwRange },
                    }),
                    _c(
                      "v-row",
                      { staticClass: "mt-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pt-0" },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                "hide-details": "",
                                label: "軸を揃える",
                                ripple: false,
                                disabled: !_vm.checkboxShown,
                              },
                              model: {
                                value: _vm.isChecked,
                                callback: function ($$v) {
                                  _vm.isChecked = $$v
                                },
                                expression: "isChecked",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isNoData
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pl-4 py-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-alert",
                                  { attrs: { dense: "", type: "warning" } },
                                  [_vm._v("どの週にもデータがありません。 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { ref: "chart" }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
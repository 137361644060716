var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-card",
              {
                directives: [
                  {
                    name: "resize",
                    rawName: "v-resize",
                    value: _vm.onResize,
                    expression: "onResize",
                  },
                ],
                attrs: {
                  height: _vm.isDashboard ? 450 : 600,
                  loading: _vm.isLoading,
                  elevation:
                    !_vm.isDashboard || !_vm.isEditMode ? 2 : hover ? 20 : 5,
                },
              },
              [
                _c(
                  "v-card-title",
                  [
                    _vm._v(" " + _vm._s(_vm.name) + "の時系列推移 "),
                    _c("v-spacer"),
                    _c(
                      "v-fade-transition",
                      [
                        !_vm.isDashboard || _vm.isEditMode
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.onClickClose },
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "pt-1 pb-0", attrs: { cols: "4" } },
                          [
                            _c("v-select", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                "offset-y": "",
                                label: "描画するデータ",
                                items: _vm.dataTypes,
                                "item-text": "text",
                                "itme-value": "value",
                                "prepend-inner-icon": "mdi-chart-bar",
                              },
                              model: {
                                value: _vm.selectedDataType,
                                callback: function ($$v) {
                                  _vm.selectedDataType = $$v
                                },
                                expression: "selectedDataType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-1 pb-0", attrs: { cols: "4" } },
                          [
                            _c("v-select", {
                              attrs: {
                                dense: "",
                                "offset-y": "",
                                outlined: "",
                                "item-text": "text",
                                "itme-value": "value",
                                label: "表示単位",
                                "prepend-inner-icon": "mdi-chart-bar",
                                items: _vm.dataUnits,
                              },
                              model: {
                                value: _vm.dataUnit,
                                callback: function ($$v) {
                                  _vm.dataUnit = $$v
                                },
                                expression: "dataUnit",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-1 pb-0", attrs: { cols: "4" } },
                          [
                            _c(
                              "v-slide-x-transition",
                              [
                                _vm.selectedDataType === "shipments"
                                  ? _c("v-combobox", {
                                      attrs: {
                                        "deletable-chips": "",
                                        dense: "",
                                        multiple: "",
                                        outlined: "",
                                        "small-chips": "",
                                        label: "比較する予測値を選ぶ",
                                        items: _vm.comboboxItems,
                                        "item-text": "text",
                                        "item-value": "value",
                                      },
                                      model: {
                                        value: _vm.combobox,
                                        callback: function ($$v) {
                                          _vm.combobox = $$v
                                        },
                                        expression: "combobox",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("yw-range-menu", {
                      attrs: {
                        "row-class": "mt-0",
                        "acceptable-year": _vm.ywRangeAcceptableYear,
                        "initial-yw-range": [_vm.startYw, _vm.endYw],
                        "chip-props": {
                          small: _vm.isDashboard ? true : false,
                          outlined: true,
                          label: true,
                        },
                      },
                      on: { click: _vm.getYwRange },
                    }),
                    _vm.isNoData
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pl-4 py-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-alert",
                                  { attrs: { dense: "", type: "warning" } },
                                  [_vm._v("どの週にもデータがありません。 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { ref: "chart" }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-chip",
                _vm._g(
                  _vm._b(
                    { class: _vm.chipClass, attrs: { disabled: _vm.disabled } },
                    "v-chip",
                    { ...attrs, ..._vm.chipProps },
                    false
                  ),
                  on
                ),
                [
                  _vm._v(_vm._s(_vm.chipText)),
                  _c("v-icon", { attrs: { "x-small": "", right: "" } }, [
                    _vm._v("mdi-chevron-down"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        { attrs: { "max-height": "200" } },
        [
          _c(
            "v-list",
            { staticClass: "py-0" },
            _vm._l(_vm.listItems, function (item, idx) {
              return _c(
                "v-list-item",
                {
                  key: `item_${idx}`,
                  attrs: { dense: "" },
                  on: {
                    click: function ($event) {
                      return _vm.emitListItem(item)
                    },
                  },
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(typeof item !== "object" ? item : item.text)),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }